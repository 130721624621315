import axios from '@/libs/axios'

export default class Inventories {

    getProvisionalIncomes(query) {
        return axios.get(`/v2/IngresosProvicionales/GetAllByFilters`, { params: query})
    }
    
    addProvisionalIncome(payload) {
        return axios.post(`/v2/IngresosProvicionales/Store`, payload)
    }

}